type FormatPriceArgs = {
  amount: number;
  locale?: string;
  options?: Omit<Intl.NumberFormatOptions, 'style'>;
};

export const SHORT_DATE_FORMAT = 'E, LLL d';
export const SHORT_DATE_FORMAT_WITH_TIME = 'E, LLL d, p';
export const LONG_DATE_FORMAT = 'haaa E, LLL d';
export const MONTH_AND_DAY_DATE_FORMAT = 'LLL d';

export const formatPrice = ({ amount, locale = 'en-US', options = {} }: FormatPriceArgs) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    ...options,
  }).format(amount / 100);
};

export const reversePriceFormat = (price: string) => {
  // @ts-ignore - replaceAll was introduced in node 15
  return parseInt(price.replaceAll('$', '').replaceAll('.', ''), 10);
};

export const formatQuantity = (quantity: number | string) => {
  const numericQuantity = typeof quantity === 'number' ? quantity : parseInt(quantity, 10);
  return numericQuantity > 0 ? numericQuantity / 100 : numericQuantity;
};

type PluralizeOptions = {
  hideNumber?: boolean;
};

export const pluralize = (nr: number, singular: string, plural: string, options?: PluralizeOptions) => {
  if (nr === 1) {
    return options?.hideNumber ? singular : `${nr} ${singular}`;
  }

  return options?.hideNumber ? plural : `${nr} ${plural}`;
};

type FormatNumberOptions = Pick<Intl.NumberFormatOptions, 'maximumFractionDigits'>;

export const reverseNumberFormat = (formattedNumber: string) => {
  // Remove any non-numeric characters (except for the decimal point)
  const numericString = formattedNumber.replace(/[^0-9.]/g, '');
  return parseFloat(numericString);
};

export const formatNumber = (nr: number, options?: FormatNumberOptions) => {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, ...options }).format(nr);
};
