// @ts-nocheck
import { KEYBOARD_KEYS } from '@holo/constants';
import { useCallback, useEffect, useId, useState } from 'react';
import type { NativeSyntheticEvent, TextInputChangeEventData, TextInputKeyPressEventData } from 'react-native';
import { Input as TamaguiInput, Label, XStack, YStack, styled } from 'tamagui';
import type { GetProps, InputProps } from 'tamagui';
import Button from '../Button/Button';
import type { IconName } from '../Icon/Icon';
import Text from '../Text/Text';

type InDecButtonProps = {
  size?: GetProps<typeof Wrapper>['size'];
  icon: IconName;
  label: string;
} & Omit<GetProps<typeof Button>, 'size' | 'children'>;

const IncDecButton = ({ size = 'md', icon, label, ...restButtonProps }: InDecButtonProps) => {
  return (
    <Button
      p="$0"
      // compensate for border 1px top and bottom
      width={size === 'md' ? 32 - 2 : 40 - 2}
      height="100%"
      variant={icon === 'trash' ? 'error' : 'secondary'}
      backgroundColor="$transparent"
      borderWidth={1}
      borderColor="$border"
      m={-1}
      hoverStyle={{ borderColor: '$border', backgroundColor: '$subtlePale' }}
      focusStyle={{ borderColor: '$border', backgroundColor: '$subtlePale' }}
      pressStyle={{ borderColor: '$border', backgroundColor: '$subtlePale' }}
      icon={icon}
      label={label}
      {...restButtonProps}
    />
  );
};

const Wrapper = styled(XStack, {
  backgroundColor: '$background',
  alignItems: 'center',
  borderRadius: 10000,
  borderWidth: 1,
  borderColor: '$border',
  width: '100%',
  variants: {
    size: {
      md: {
        height: 32,
        width: 100,
        $sm: { width: 100 },
      },
      lg: {
        height: 40,
        width: 150,
        $sm: { width: 150 },
      },
    },
  },
});

type Props = {
  value: number;
  autoFocus?: boolean;
  label?: string;
  error?: string;
  onChange: (newValue: number) => void;
  onBlur?: InputProps['onBlur'];
  onKeyPress?: InputProps['onKeyPress'];
  showTrashIconWhenOneLeft?: boolean;
  loading?: boolean;
  size?: GetProps<typeof Wrapper>['size'];
  wrapperProps?: Omit<GetProps<typeof Wrapper>, 'size'>;
};

const CounterInput = ({
  value,
  autoFocus,
  label,
  error,
  onChange,
  onBlur = () => {},
  onKeyPress = () => {},
  showTrashIconWhenOneLeft = true,
  loading,
  size = 'md',
  wrapperProps = {},
}: Props) => {
  const id = useId();
  const [internalValue, setInternalValue] = useState(value);
  const [shouldOverride, setShouldOverride] = useState(false);

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  const handleChangeInternalValue = useCallback((newValue: number) => {
    setInternalValue(newValue);
    onChange(newValue);
  }, []);

  const handleOnFocus = () => {
    setShouldOverride(true);
  };

  const handleOnBlur: InputProps['onBlur'] = (e) => {
    setShouldOverride(false);
    onBlur(e);
  };

  const handleDecrement = () => {
    handleChangeInternalValue(Math.max(0, internalValue - 1));
  };

  const handleIncrement = () => {
    handleChangeInternalValue(internalValue + 1);
  };

  const handleOnChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    const inputText = e.nativeEvent.text;
    if (inputText === '') {
      handleChangeInternalValue(0);
    }

    const parsed = parseInt(inputText, 10);

    if (Number.isNaN(parsed)) {
      return;
    }

    if (shouldOverride) {
      // @ts-ignore - nativeEvent.data is not in the type definition
      const lastTypedNumber = parseInt(e.nativeEvent.data, 10);
      handleChangeInternalValue(Math.max(0, lastTypedNumber));
      setShouldOverride(false);
      return;
    }

    handleChangeInternalValue(Math.max(0, parsed));
  };

  const handleOnKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === KEYBOARD_KEYS.ARROW_DOWN) {
      e.preventDefault();
      // @ts-ignore, shiftKey is not in the type definition
      const nextValue = e.nativeEvent.shiftKey ? internalValue - 10 : internalValue - 1;
      handleChangeInternalValue(Math.max(0, nextValue));
    }

    if (e.nativeEvent.key === KEYBOARD_KEYS.ARROW_UP) {
      e.preventDefault();
      // @ts-ignore, shiftKey is not in the type definition
      const nextValue = e.nativeEvent.shiftKey ? internalValue + 10 : internalValue + 1;
      handleChangeInternalValue(nextValue);
    }

    onKeyPress(e);
  };

  return (
    <YStack position="relative" {...wrapperProps}>
      {label && (
        <Label cursor="pointer" htmlFor={id} fontSize="$10" color="$contrast" marginBottom="$2">
          {label}
        </Label>
      )}
      <Wrapper size={size} className="counter-input-animation">
        <IncDecButton
          onPress={handleDecrement}
          icon={showTrashIconWhenOneLeft && internalValue === 1 ? 'trash' : 'minus'}
          disabled={internalValue === 0}
          size={size}
          label="Decrement"
          testID="counter-input-dec-button"
        />
        <TamaguiInput
          id={id}
          flex={1}
          keyboardType="number-pad"
          value={loading ? '' : internalValue.toString()}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          autoFocus={autoFocus}
          textAlign="center"
          borderWidth={0}
          focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
          height="100%"
          borderRadius={0}
          fontSize={size === 'lg' ? '$14' : '$10'}
          p={0}
        />
        <IncDecButton
          onPress={handleIncrement}
          icon="plus"
          size={size}
          label="Increment"
          testID="counter-input-inc-button"
        />
      </Wrapper>

      {error && (
        <Text color="$error" fontSize="$10" position="absolute" top="calc(100% + 4px)">
          {error}
        </Text>
      )}
    </YStack>
  );
};

export default CounterInput;
